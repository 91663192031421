export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosh sahifa"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur paketlar"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xizmatlar"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bo'g'lanish"])},
  "carouselText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunyoni Biz Bilan Kashf Eting"])},
  "carouselText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakka Va Guruh Sayohatlari"])},
  "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirish"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiqish"])},
  "yourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismingiz"])},
  "yourSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiyangiz"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuborish"])},
  "aboutContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz kichik guruh-trekking turlari, ekologik sayohatlar va madaniy va sarguzashtli sayohatlarga ixtisoslashganmiz; bizning barcha turlarimiz O'zbekistonni juda o'ziga xos tarzda kashf qilish uchun noyob imkoniyatlarni taqdim etadi."])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamkorlar"])},
  "staffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xodimlar"])},
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijozlar"])},
  "exploreMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batafsil"])},
  "ourServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning xizmatlar"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viza xizmatlari"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviabiletlar"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temir yo'l chiptalari"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport xizmatlari"])},
  "service5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehmonxonani band qilish"])},
  "service6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayohat bo'yicha qo'llanmalar"])},
  "ourPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning sayohat paketlarimiz"])},
  "ourPackagesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajribali mutaxassislardan tashkil topgan jamoamiz ajoyib sayohatingizdan maksimal darajada foydalanishga yordam beradi"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A'zo bo'lish"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan sh."])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektron pochtangiz"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavzu"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyurtma qilish"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon raqamingiz"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orqaga"])},
  "fillForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maydonlarni to‘ldiring"])}
}