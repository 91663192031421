export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tour packages"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "carouselText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover The World With Us"])},
  "carouselText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Trips and Group Journeys"])},
  "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out"])},
  "yourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "yourSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your surname"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "aboutContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We specialize in small group-trekking tours, Eco tours, and cultural and adventure tours; all our tours offer unique opportunities to explore Uzbekistan in a very authentic way."])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "staffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staffs"])},
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "exploreMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore More"])},
  "ourServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa services"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airline tickets"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Railway tickets"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport services"])},
  "service5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Reservation"])},
  "service6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Guides"])},
  "ourPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Tour Packages"])},
  "ourPackagesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of experienced professionals will help you get the most out of your amazing trip"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan city"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "fillForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete fields"])}
}